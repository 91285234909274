import Axios from "@/common/js/ajax";
import request from "common/utils/request";

const api = {
  //白底图
  postWhiteBackground: "/api/v1/image-process/white-background",
  //白底图接口
  cutOut(file) {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return request({
      url: this.postWhiteBackground,
      method: "post",
      config,
      data: formData,
    });
  },
  cutOutImageByUrl(url) {
    return request({
      url: "/api/v1/image-process/white-background/ByUrl",
      method: "get",
      params: { url },
    });
  },
  compressImage(file) {
    const formData = new FormData();
    formData.append("file", file);
    return Axios({
      url: "/api/v1/image-process/compress",
      method: "post",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
    });
  },
  compressImageByUrl(url) {
    return Axios({
      url: "/api/v1/image-process/compress/by-url",
      method: "post",
      params: { url },
    });
  },
};
export default api;
