<template>
  <el-dialog
    :visible="p_dialogVisible"
    title="上传图片"
    width="989px"
    append-to-body
    @close="closeDialog"
  >
    <div class="group">
      <span class="tip">
        单张不超过3M,支持Jpeg，Jpg，Png，建议尺寸小于1000px*1000px。图片名称只允许输入英文、中文和数字。</span
      >
    </div>
    <div class="upload-area">
      <el-upload
        ref="upload"
        :class="$route.path === '/ai-home-page' ? 'reset-dragger' : ''"
        list-type="picture-card"
        drag
        :file-list="fileList"
        action="#"
        :http-request="httpRequest"
        :auto-upload="false"
        :on-change="handleChange"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :on-error="handleError"
        :on-exceed="handleExceed"
        :limit="maxLimit"
        multiple
      >
        <template #default>
          <div
            v-if="showIcon"
            style="
              width: 100px;
              height: 100px;
              line-height: 20px;
              padding-top: 20px;
            "
          >
            <i class="el-icon-plus"></i>
            <br />
            上传图片
            <br /><span class="tip">拖拽到此</span>
          </div>
          <i v-else class="el-icon-circle-close"></i>
        </template>
      </el-upload>
    </div>
    <template #footer>
      <el-button
        style="margin-left: 10px"
        type="primary"
        size="small"
        :loading="status.showUploadLoading"
        :disabled="fileListLength === 0"
        @click="submitUpload"
        >开始上传</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import { dialogMixin } from "@/common/js/mixin";
import photoshopApi from "pages/product/api/photoshop";
import notify from "@/common/js/notify";

export default {
  name: "UploadImage",
  mixins: [dialogMixin],
  props: {
    maxLimit: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      size: "mini",
      api: photoshopApi,
      fileList: [],
      failList: [],
      Base64Images: [],
      fileReader: "",
      status: {
        showUploadLoading: false,
      },
    };
  },
  computed: {
    showIcon() {
      return this.fileList.length !== 20;
    },
    fileListLength() {
      return this.fileList.length;
    },
  },
  watch: {
    Base64Images: {
      handler(val) {
        this.Base64Images = val;
        this.$emit("update:base64img", val);
      },
    },
  },
  mounted() {
    this.fileReader = new FileReader();
  },
  methods: {
    httpRequest(options) {
      console.log(options);
      let file = options.file;
      // console.log(file);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        let base64Str = fileReader.result;
        this.Base64Images.push({
          name: file.name,
          base64: base64Str,
          date: new Date().getTime(),
        });
        this.fileList = this.fileList.filter((item) => item.name !== file.name);
        this.uploadOneFinish();
      };
      fileReader.readAsDataURL(file);
    },
    uploadOneFinish() {
      if (this.fileList.length === 0) {
        this.uploadFinish();
      }
    },
    submitUpload() {
      this.status.showUploadLoading = true;
      this.$refs.upload.submit();
      this.status.showUploadLoading = false;
      this.setProductQuotationList();
    },
    closeDialog() {
      console.log("close dialog");
      this.p_dialogVisible = false;
      this.fileList = [];
      this.Base64Images = [];
    },
    handleChange(file, fileList) {
      fileList = fileList.filter((item) => this.isImage(item));
      this.fileList = fileList;
      console.log({ file, fileList });
      if (file.status === "fail") {
        this.failList.push(file);
      }
      if (
        fileList.length === 0 ||
        fileList.every((item) => item.status === "success")
      ) {
        if (this.failList.length === 0) {
          console.log("上传成功");
        } else {
          const nameList = this.failList.map((item) => item.name);
          const name = nameList.join("\n");
          notify.warn(`您已上传过以下图片:\n ${name}`);
        }
        this.uploadFinish();
      }
    },
    handleSuccess(response, file, fileList) {
      console.log(response, file, fileList);
      this.Base64Images.push(response);
    },
    handleError(response) {
      console.log(response);
    },
    handleExceed(files, fileList) {
      console.log(files, fileList);
      notify.warn(`抱歉哦，最多只能上传${this.maxLimit}张图片`);
    },
    handleHttpRequest(val) {
      console.log(val);
    },
    isImage(item) {
      const name = item.name.toLowerCase();
      const size = item.size;
      if (name.endsWith(".jpg") && this.maxImagesSize(size)) {
        return true;
      }
      if (name.endsWith(".png") && this.maxImagesSize(size)) {
        return true;
      }
      if (name.endsWith(".gif") && this.maxImagesSize(size)) {
        return true;
      }
      if (name.endsWith(".jpeg") && this.maxImagesSize(size)) {
        return true;
      }
      if (name.endsWith(".bmp") && this.maxImagesSize(size)) {
        return true;
      }
      notify.err("上传失败原因：图片格式不对、图片过大");
      return false;
    },
    maxImagesSize(size) {
      // 15M === 15728640Bytes
      return size < 15728640;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    uploadFinish() {
      this.$emit("updateImages", this.Base64Images);
      this.closeDialog();
      this.fileList = [];
      this.failList = [];
    },
    setProductQuotationList() {
      const productQuotationList = this.fileList.map((m) => {
        return {
          url: m.url,
          check: false,
        };
      });
      this.$emit("getImages", productQuotationList);
    },
  },
};
</script>

<style lang="stylus" scoped>
.upload-area {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  min-height: 400px;
  margin: 12px 0 0 0;
  padding .5rem
  border: 1px solid rgb(221, 221, 221);
  & >>> .el-upload--picture-card {
    border none
    background-color: #fff
  }
  .reset-dragger {
    & >>> .el-upload-dragger, .el-upload {
      width 100px
      height 100px
    }
    & >>> li {
      width 100px
      height 100px
    }
  }
}
.tip {
  color: #888;
}
</style>
