<template>
  <el-dialog
    top="5vh"
    title="生成报价单"
    :visible.sync="p_dialogVisible"
    width="1200px"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleClose"
    @close="close"
  >
    <operator-container>
      <template #content>
        <div class="header-btn">
          <div>
            <el-button
              style="margin-left: 0.5rem"
              type="success"
              :size="size"
              @click="handleUploadImage"
              >上传产品图片
            </el-button>
          </div>
          <div>
            <el-link
              type="primary"
              :href="getQuotationUseCourseLink"
              target="_blank"
              >使用教程
            </el-link>
          </div>
        </div>

        <ul class="image-group">
          <li
            v-for="(item, index) in imageList"
            :key="index"
            @click="handleImageChanged(item)"
          >
            <img
              :class="item.check ? 'active' : ''"
              class="image-item"
              :src="item.url"
            />
          </li>
        </ul>
        <div v-if="imageList.length" class="container-nav">
          <div class="nav">
            <el-tabs v-model="activeName" type="card">
              <el-tab-pane label="全部" name="all"></el-tab-pane>
              <el-tab-pane label="已选择" name="check"></el-tab-pane>
            </el-tabs>
          </div>
          <div class="nav">
            <el-button-group>
              <el-button
                size="small"
                icon="el-icon-sort-up"
                @click="handleSort('asc')"
                >升序排序
              </el-button>
              <el-button
                size="small"
                icon="el-icon-sort-down"
                @click="handleSort('desc')"
                >降序排序
              </el-button>
            </el-button-group>
          </div>
        </div>
        <div
          v-if="activeName === 'all'"
          v-loading="status.globalLoading"
          class="container-product"
        >
          <ul class="product-info">
            <li
              v-for="(item, index) in productInfo"
              :key="index"
              class="product-item"
            >
              <el-link
                :underline="false"
                :href="item.detailUrl"
                target="_blank"
              >
                <el-image
                  class="image-item"
                  :src="item.mainImage"
                  fit="contain"
                />
                <h4>{{ item.subject }}</h4>
              </el-link>
              <ul class="inner-container">
                <li
                  v-for="(innerItem, index2) in item.propertyValuePairs"
                  :key="index2"
                  class="inner-item"
                >
                  <p>{{ innerItem.pValue }}</p>
                  <p>{{ innerItem.vValue }}</p>
                </li>
              </ul>
              <h3>￥{{ item.priceInfo }}</h3>
              <el-link
                :underline="false"
                :href="item.companyUrl"
                target="_blank"
              >
                <h4>{{ item.companyName }}</h4>
              </el-link>
              <el-button
                :type="item.check ? 'danger' : 'primary'"
                :size="size"
                @click="handleCheckImage(item)"
              >
                {{ item.check ? "取消选择" : "选择" }}
              </el-button>
            </li>
          </ul>
          <div style="width: 100%; text-align: center">
            <el-button
              v-if="productInfo?.length > 0"
              v-loading="status.globalLoading"
              type="success"
              round
              @click="loadMoreData"
              >加载更多</el-button
            >
          </div>
        </div>
        <div v-if="activeName === 'check'">
          <ul class="image-group">
            <li
              v-for="(item, index) in checkImageList"
              :key="index"
              class="check-item"
            >
              <img
                class="image-item"
                :src="item.mainImage"
                @click="handleExportImage(item)"
              />
              <el-button
                style="width: 90%"
                type="danger"
                :size="size"
                @click="handleRemoveImage(item)"
                >移除
              </el-button>
            </li>
          </ul>
        </div>
        <div v-if="!activeName && imageList.length" class="empty-prompt">
          <h2>抱歉出错了，请重试</h2>
        </div>
      </template>
      <template #dialog>
        <upload-image
          :dialog-visible.sync="status.showUploadImageDialog"
          @getImages="getImages"
        ></upload-image>
      </template>
    </operator-container>
    <template #footer>
      <span class="dialog-footer">
        <div :class="!checkImageList.length ? 'footer-right' : 'footer-group'">
          <div
            v-if="checkImageList.length && activeName === 'check'"
            class="footer-item"
          >
            <span>价格换算公式</span>
            <span>人民币价格&nbsp;&divide;</span>
            <el-input
              v-model="exchangeRate"
              :size="size"
              clearable
              placeholder="汇率"
            ></el-input>
            <el-select v-model="operator" :size="size">
              <el-option
                v-for="item in options.operators"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-input
              v-model="profits"
              :size="size"
              clearable
              placeholder="利润空间"
            ></el-input>
            <el-link
              type="primary"
              href="https://www.huilv.cc/USD_CNY/"
              :underline="false"
              target="_blank"
              >查看汇率</el-link
            >
            <el-radio v-model="mainImageRadio" label="single"
              >单个报价</el-radio
            >
            <el-radio v-model="mainImageRadio" label="batch">批量报价</el-radio>
          </div>
          <div>
            <el-button :size="size" @click="p_dialogVisible = false"
              >取 消</el-button
            >
            <el-button
              :disabled="!checkImageList.length"
              type="primary"
              :size="size"
              :loading="status.exportLoading"
              @click="handleExport"
              >导出
            </el-button>
          </div>
        </div>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import * as chromeExtension from "@/common/js/chromeExtension";
import { YU_QUE_URL_PREFIX } from "@/common/js/config";
import { dialogMixin } from "@/common/js/mixin";
import notify from "@/common/js/notify";
import { getNested, isArrayLength } from "@/common/js/utils";
import OperatorContainer from "@/components/container/operator-container.vue";
import productApi from "@/pages/product/api";
import UploadImage from "@/pages/product/views/photo-shop/dialog/upload-image.vue";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";

export default {
  name: "ProductQuotationImage",
  components: {
    OperatorContainer,
    UploadImage,
  },
  mixins: [dialogMixin],
  props: {},
  data() {
    return {
      size: "mini",
      activeName: "",
      status: {
        showUploadImageDialog: false,

        isActive: false,
        isMultiple: false,

        globalLoading: false,
        exportLoading: false,
      },
      imageList: [],
      productInfo: [],
      checkImageList: [],
      currentUrl: "",
      exchangeRate: "",
      operator: "multiply",
      profits: "",
      options: {
        operators: [
          { label: "加", value: "plus" },
          { label: "乘", value: "multiply" },
        ],
      },
      mainImageRadio: "batch",
      beginPage: 1,
      imageBeginPage: {},

      cachedProductInfo: {},
    };
  },
  computed: {
    getQuotationUseCourseLink() {
      return `${YU_QUE_URL_PREFIX}nB0jN`;
    },
    currentCheckImage() {
      return this.imageList.find((item) => item.check);
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleUploadImage() {
      this.status.showUploadImageDialog = true;
    },
    async getImages(images) {
      this.imageList = images;
      await this.handleImageChanged(images[0]);
    },
    loadMoreData() {
      console.log("product-quotation-image scroll load more data");
      this.handleImage(this.imageList.find((f) => f.check));
    },
    // 切换图片时调用
    handleImageChanged(item) {
      // 缓存原本选中的数据
      if (this.currentCheckImage) {
        this.cachedProductInfo[this.currentCheckImage.url] = [
          ...this.productInfo,
        ];
      }

      this.activeName = "all";
      this.imageUncheck();
      item.check = !item.check;
      const cachedProducts = this.cachedProductInfo[item.url];
      // 切换图片时重置列表
      if (!_.isEmpty(cachedProducts)) {
        this.productInfo = [];
        this.productInfo.push(...cachedProducts);
      } else {
        this.productInfo = [];
        this.handleImage(item);
      }
    },
    async handleImage(item) {
      this.status.globalLoading = true;
      const message = {
        type: "searchProductBy1688Image",
        query: {
          imageUrl: item.url,
          beginPage: this.imageBeginPage[item.url],
        },
      };
      function mapToProductInfo(data) {
        return data
          .map((m) => {
            return {
              companyName: getNested(m, "company", "name"),
              companyUrl: getNested(m, "company", "url"),
              mainImage: getNested(m, "image", "imgUrl"),
              detailUrl: getNested(m, "information", "detailUrl"),
              subject: getNested(m, "information", "subject"),
              priceInfo:
                getNested(m, "tradePrice", "offerPrice", "valueString") || 0,
              quantityPrices:
                getNested(m, "tradePrice", "offerPrice", "quantityPrices") ||
                [],
              propertyValuePairs:
                getNested(
                  m,
                  "information",
                  "propertyValueModel",
                  "propertyValuePairs"
                ) || [],
              brief: getNested(m, "information", "brief").split(" "),
              check: false,
            };
          })
          .sort((a, b) => b.priceInfo - a.priceInfo);
      }
      await chromeExtension.sendMessage(message, (res) => {
        if (isArrayLength(res.data)) {
          this.productInfo.push(...mapToProductInfo(res.data));
        }
        this.status.globalLoading = false;
      });
      if (!this.imageBeginPage[item.url]) {
        this.imageBeginPage[item.url] = 1;
      }
      this.imageBeginPage[item.url]++;
      this.currentUrl = this.imageList.find((f) => f.check).url;
    },
    handleSort(type) {
      if (type === "desc") {
        this.productInfo = this.productInfo.sort(
          (a, b) => b.priceInfo - a.priceInfo
        );
      } else {
        this.productInfo = this.productInfo.sort(
          (a, b) => a.priceInfo - b.priceInfo
        );
      }
    },
    imageUncheck() {
      for (const image of this.imageList) {
        image.check = false;
      }
    },
    async handleDelete(index) {
      this.imageList.splice(index, 1);
      if (isArrayLength(this.imageList)) {
        await this.handleImage(this.imageList[0]);
      }
    },
    handleExportImage(item) {
      item.check = !item.check;
    },
    handleRemoveImage(item) {
      this.checkImageList = this.checkImageList.filter(
        (f) => f.detailUrl !== item.detailUrl
      );
      this.productInfo.find(
        (f) => f.detailUrl === item.detailUrl
      ).check = false;
    },
    handleCheckImage(item) {
      this.$set(item, "url", this.currentUrl);
      item.check = !item.check;
      if (!item.check) {
        this.checkImageList = this.checkImageList.filter(
          (f) => f.detailUrl !== item.detailUrl
        );
        notify.succ("已移除");
      } else {
        if (!this.checkImageList.includes(item.detailUrl)) {
          this.checkImageList.push(item);
        }
        notify.succ("已添加");
      }
    },
    async getExportProductQuotationList() {
      const productQuotationList = [];
      const checkedImageList = this.checkImageList.filter((f) => f.check);
      for (const item of checkedImageList) {
        const productInfo = {};
        // 图片
        if (this.mainImageRadio === "batch") {
          productInfo.mainImage = await this.urlToBase64(item.url);
        } else {
          productInfo.mainImage = await this.urlToBase64(item.mainImage);
        }
        // 起订量
        if (item?.quantityPrices?.length) {
          productInfo.quantity = getNested(
            item,
            "quantityPrices",
            "0",
            "quantity"
          );
        }
        // 价格
        productInfo.price = item.priceInfo;
        for (const innerItem of item.brief) {
          // 货号
          if (["货号", "型号"].some((s) => innerItem.includes(s))) {
            productInfo.model = this.getSubstringText(innerItem);
          }
          // 类型
          // if (["产品类型", "产品类别"].some(s => innerItem.includes(s))) {
          //   productInfo.type = this.getSubstringText(innerItem);
          // }
          // 面料名称
          if (innerItem.includes("面料名称")) {
            productInfo.material = this.getSubstringText(innerItem);
          }
          // 颜色
          if (innerItem.includes("颜色")) {
            productInfo.color = this.getSubstringText(innerItem);
          }
          // 尺码
          if (["尺码", "尺寸", "大小"].some((s) => innerItem.includes(s))) {
            productInfo.size = this.getSubstringText(innerItem);
          }
        }
        // 产品链接
        productInfo.detailUrl = item.detailUrl;
        productQuotationList.push(productInfo);
      }
      return productQuotationList;
    },
    translateProductQuotationList(translateList, imageList) {
      return productApi.newProduct
        .translateDescription({ value: JSON.stringify(translateList) })
        .then((res) => {
          imageList.forEach((item, index) => {
            res.data[index].mainImage = item;
          });
          return res.data;
        })
        .catch((err) => {
          notify.err(`产品报价单翻译失败了:${err}`);
          this.status.exportLoading = false;
        });
    },
    productPriceConversion(translateProductQuotationList) {
      for (const products of translateProductQuotationList) {
        let conversionPrices = 0;
        if (this.operator === "multiply") {
          conversionPrices =
            (products.price / this.exchangeRate) * this.profits;
        } else {
          conversionPrices = products.price / this.exchangeRate + +this.profits;
        }
        products.price = conversionPrices.toFixed(2);
      }
    },
    exportProductQuotationInfo(productQuotationList) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("sheet1");
      worksheet.columns = [
        { header: "Photo", key: "mainImage", width: 15 },
        { header: "Item No.", key: "model", width: 15 },
        // {header: "产品类型", key: "type", width: 15},
        { header: "Material", key: "material", width: 15 },
        { header: "Color", key: "color", width: 15 },
        { header: "Size", key: "size", width: 15 },
        { header: "Quantity", key: "quantity", width: 15 },
        { header: "Price", key: "price", width: 15 },
        { header: "Source", key: "detailUrl", width: 50 },
      ];
      const startRow = 1;
      const data = JSON.parse(JSON.stringify(productQuotationList));
      const tableHead = {
        mainImage: "Photo",
        model: "Item No.",
        // type: "产品类型",
        material: "Material",
        size: "Color",
        color: "Size",
        quantity: "Quantity",
        price: "Price",
        detailUrl: "Source",
      };
      data.unshift(tableHead);
      data.forEach((item, index) => {
        const row = worksheet.getRow(startRow + index);
        if (index !== 0) {
          // 图片
          const mainImage = workbook.addImage({
            base64: item.mainImage,
            extension: "png",
          });
          worksheet.addImage(mainImage, {
            tl: { col: 0, row: index },
            ext: { width: 120, height: 106 },
          });

          // 产品链接
          worksheet.getCell(`H${index + 1}`).value = {
            text: "产品链接",
            hyperlink: item.detailUrl,
            tooltip: item.detailUrl,
          };
          const rowData = JSON.parse(JSON.stringify(item));
          rowData.mainImage = mainImage;
          row.values = rowData;
        }
        // 样式处理
        if (index === 0) {
          // 表头
          row.height = 25;
          row.font = {
            name: "Microsoft YaHei",
            size: 12,
            underline: false,
            bold: false,
          };
          row.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "C0C0C0" },
            bgColor: { argb: "C0C0C0" },
          };
        } else {
          // 内容
          row.height = 80;
          row.font = {
            name: "Microsoft YaHei",
            size: 12,
            underline: false,
            bold: false,
          };
        }
        row.alignment = { vertical: "middle", horizontal: "center" };
        row.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const currentTime = moment().format("YYYY-MM-DD HH-mm-ss");
        saveAs(blob, currentTime + "产品报价单.xlsx");
      });
    },
    async getTranslateProductQuotationList(productQuotationList) {
      const imageList = productQuotationList.map((m) => m.mainImage);
      const translateList = productQuotationList.map((m) => {
        return {
          color: m.color,
          material: m.material,
          model: m.model,
          price: m.price,
          quantity: m.quantity,
          size: m.size,
          detailUrl: m.detailUrl,
        };
      });
      return await this.translateProductQuotationList(translateList, imageList);
    },
    async handleExport() {
      this.status.exportLoading = true;
      const productQuotationList = await this.getExportProductQuotationList();
      notify.info("产品报价单开始翻译");
      let translateProductQuotationList =
        await this.getTranslateProductQuotationList(productQuotationList);
      // 价格换算
      if (this.exchangeRate && this.profits) {
        this.productPriceConversion(translateProductQuotationList);
      }
      this.exportProductQuotationInfo(translateProductQuotationList);
      notify.succ("产品报价单导出成功");
      this.status.exportLoading = false;
    },
    getSubstringText(item) {
      return item.substring(item.indexOf(":") + 1);
    },
    urlToBase64(url) {
      return new Promise((resolve) => {
        fetch(url)
          .then((data) => {
            return data.blob();
          })
          .then((blob) => {
            let reader = new FileReader();
            reader.onloadend = () => {
              // console.log('base64地址：', dataURL)
              resolve(reader.result);
            };
            reader.readAsDataURL(blob);
          });
      });
    },
    close() {
      this.imageList = [];
      this.checkImageList = [];
      this.productInfo = [];
      this.activeName = "";
      this.mainImageRadio = "batch";
    },
  },
};
</script>

<style lang="stylus" scoped>

.header-btn {
  display flex
  justify-content space-between
  align-items center
}

.image-group {
  display flex
  justify-content flex-start
  align-items center
  flex-wrap wrap

  .check-item {
    display flex
    flex-direction column
    justify-content center
    align-items center
    width 10%
    margin-bottom .5rem
  }

  .active {
    border 2px $prim solid
  }

  .image-item {
    width 100px
    height 100px
    margin 10px
    box-shadow 1px 1px 10px #DCDFE6
  }
}

.empty-prompt {
  display flex
  justify-content center
  align-items center

  h2 {
    margin-top 2rem
    color $warn
  }
}

.container-nav {
  display flex
  justify-content space-between
  align-items center

  nav {
    width 10rem
  }
}

.container-product {
  height 40vh
  overflow auto

  .product-info {
    display flex
    justify-content flex-start
    align-items flex-start
    flex-wrap wrap

    .product-item {
      width 20%
      margin-bottom .5rem

      .active {
        border 2px $prim solid
      }

      .image-item {
        width 95%
        height 200px
        box-shadow 1px 1px 10px #DCDFE6
      }

      h4 {
        width 200px
        margin .5rem 0
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      }

      .inner-container {
        display flex
        justify-content center
        align-items center

        .inner-item {
          flex 1
          padding-bottom .3rem
          font-size .8rem

          p {
            width 60px
            margin-bottom .5rem
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
          }
        }
      }

      h3 {
        color #ff6000
      }
    }
  }
}

.footer-right {
  display flex
  justify-content right
  align-items center
}

.footer-group {
  display flex
  justify-content space-between
  align-items center

  .footer-item {
    display flex
    justify-content flex-start
    align-items center
    color #606266

    & > * {
      width 15%
      text-align left
      margin-right .5rem
    }
  }
}
</style>
